.exhibition .header {

padding: 27px 0;
    margin: 13px 25px 0;

}
.exhibition .event-page .header{
	margin:13px 15px 0;
}
.three-column{
	vertical-align: top;
}
.three-column:first-child{
	font-weight:bold;
}

.three-column img{
	max-width:100%;
}
.exhibition-page{
	margin:0 15px;
}
.three-column:nth-child(2){
	border-left:1px solid;
	border-right:1px solid;

}
.col-4 img{
	margin:5px 0;
}
.top .inset img{
	height: 80vh;
	max-width: none;
	width: auto;
}
.top .inset {
	text-align: center;
}
.top .crop-height , .top .crop-width {
	overflow: hidden;
}
.top .crop-height{
	height: 80vh;
	min-height:80vh;
}
.top .crop-width{
	width: calc(100vw - 10px);
	text-align: center;
	max-width: none;
}
.top .crop-height img{
	width: calc(100% - 30px);
	height: auto;
}
.top .crop-width img{
	height: 80vh;
	max-width: none;
	width: auto;
}
.exhibition .col-6 .row{
	margin:0;
}
.second-column .row .col-4{
	padding:10px;
}
.second-column .row .col-4:nth-child(3n + 0){
	padding-right:10px;
	padding-left:0;
}
.top img {
  margin:0 15px;
  width: calc(100vw - 60px);
  height: auto;

}
.exhibition-page header h1, .event-page header h1{
	text-align: center;
}
.top .my-carousel{
	display: block;


}
.top{
	display: block;
	width:100%;
}
/*.top img{
	width:100%;
	display: block;
	position: relative;
}*/
.top .caption{
	width: calc(100% - 50px);
	margin:10px 25px;
	padding:10px 0;
	border-bottom: 1px solid;
	border-top:1px solid;
}
.top .caption .col-8{
	text-align: center;
}
.top .slide{
	width:100vw;
	height:auto;
	display: block;
	position: relative;
}
.toolbar {
	border-top:1px solid;
	border-bottom:1px solid;
	margin:0 15px;
	width: calc(100% - 30px);
}
.toolbar .col-4:nth-child(2){
	text-align: center;

}
.archive-links h1{
	cursor: pointer;
}
.second-column{

	border-left:1px solid;
}
.toolbar .col-4 p{
	margin:0;
}
.toolbar .row{
	margin-left:0;
	margin-right:0;
}
.featured-book .col-6:first-child{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.featured-book {
	border:1px solid black;
	margin: 15px 15px;
	padding:25px;
	width:calc(100% - 30px);
}
.featured-book .book-button{
	background: black;
	color:white;
	font-weight: bold;
	display: block;
	height:155px;
	text-align: center;

}
.featured-book .book-button span{
	transform:rotate(-15deg) translateX(-35px) translateY(33px);;
	display: block;
}
.featured-book img{
	width:100%;
	border:1px solid black;
}
.toolbar .col-4{
	padding:10px 0;
/*	display: flex;
	flex-direction: column;
	justify-content: center;*/
}
.toolbar .col-4:last-child{
	text-align: right;
}
.happening nav .logo{
	display: none;
}
.top{
	margin-bottom:15px;
}

.hero-grid .caption{
	display: none;
}
.hero-grid{
	display: block;
	overflow: hidden;
	margin:10px 25px;
}
.hero-grid img{
    float:left;
    width:50%;
	
	display: inline-block;
}
.top .alice-carousel__dots {
    /* margin-top: -100px; */
    position: absolute;
    z-index: 2;
    bottom: 100px;
    left: 50%;
    transform:translateX(-50%);
}
.alice-carousel__prev-btn, .alice-carousel__next-btn{
	display: none !important;
}
.top .alice-carousel__dots-item{
	width:15px !important;
	background:transparent;
	border:1px solid white;
	height:15px !important;
}
.top .alice-carousel__dots-item:hover,.top .alice-carousel__dots-item.__active{
	background: white;
}
.happening .nav-bookstore{
    transform: rotate(-57deg) translateY(3px) translateX(-56px);
}
header img{
  max-width: 100%;
}
.banner{
	margin-bottom:25px;
	background-position: center;
	background-size: cover;
	min-height:0;
	background-repeat: no-repeat;
}
.header-with-image{
	position: absolute;
	top:50%;
	text-align: center;
	width:100%;
	transform: translateY(-50%);
}
.banner h1{
	text-align: center;
	display: block;
	width:100%;
	height:100%;
	max-width: 1000px;
	margin:0 auto;
	margin-top:150px;
	transform:translateY(-25%);
}
.lightbox-caption{
	color:white;
	display: block;
	margin-top:5px;
	font-weight: normal;
}
@media(max-width: 768px){
	.second-column{
		text-align: left;
		border-left:none;
	}
	.top .inset img{
		height: 44vh;
		max-width: none;
		width: auto;
	}
	.exhibition .event-page .header{
		margin:0;
	}
	.top img{
		width:calc(100vw - 30px);
	}
	.second-column .top .toolbar{
		border:none;
	}
	.toolbar .col-6{
		margin:0;
		padding:0;
	}
	.toolbar{

		font-size:14px;
	}
	.happening .logo{
		height:inherit;
		width:inherit;
		top:10px;
		left:10px;
	}
	.exhibition .header{
/*		background: white;*/
		padding:0;
		margin:0 15px;
		margin-top:0px;
		padding-top:25px;
		margin:0;
		width: 100%;
		left:0;
		top:0;
		border:none;
		font-weight: bold;
		position: fixed;
	}
	.happening.small-header .header{
/*		background: white;*/
		padding:0;
		margin:0 15px;
		margin-top:0px;
		padding-top:25px;
		margin:0;
		width: 100%;
		left:0;
		top:0;
		border:none;
		font-weight: bold;
		position: fixed;
	}
	.banner h1{
		margin:0;
		margin-top:15px;
		transform: translateY(0%);
	}
	.banner{
		min-height: 80px;
		padding:10px;

	}
	.exhibition-page{
	margin:0;
}
	.happening .nav-bookstore {
		top:0;
	    right: 5px;
	    max-width: 67px;
	    overflow: hidden;
	    text-decoration: none;
	    font-weight: bold;
	    width: auto;
	    display: none;
	    float: right;
	    transform: rotate(-12deg) translateY(1px) translateX(-18px);
	}
	.second-column .row .col-6{
		padding:10px;
	}
	.second-column .row .col-6:nth-child(2n + 0){
		padding-right:10px;
		padding-left:0;
	}
	.top .crop-width, .top .crop-height img {
	    width: calc(100vw - 24px);
	    margin-left: 12px;
	    text-align: center;
	    max-width: none;
	}
	.top .crop-height{
		min-height: auto;
	}
}
