.App {
   font-family:'BrownPro', sans-serif;
   font-size:24px;
   -webkit-font-smoothing: subpixel-antialiased;
}

header{
  overflow-x:hidden;
}
ul{
  list-style: none;
  padding:0;
}
b,strong{
  font-weight:normal;
}
.header{
      margin: 20px 25px 0;
      padding:10px 0;
}
.small-header nav, .small-header .logo, .small-header .banner.row{
  transform: translateY(-200px);
  transition:0.2s;
}
.pastpage.small-header nav, .pastpage.small-header .logo, .pastpage.small-header .banner.row{
  transform: none;
  transition:0.2s;
}
#light-box{
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  padding:15px;
  overflow-y:scroll;
  position: fixed;
  z-index: 9999;
  background: rgba(0,0,0,0.9);
  display: none;
}
.oops-image{
  display: block;
  margin:0 auto;
  margin-top:100px;
  max-height: 400px;
}
#light-box img{
  max-height:90vh;
}
#light-box .close svg{
  width: 75px;
  stroke:white;
}
#light-box .close svg path{
 fill: white;
 stroke:white;
}
#light-box .close{
  position: fixed; 
  top:25px;
  font-size:50px;
  right:50px;
  cursor: pointer;
  opacity: 1;
  color:white;
}
#light-box.on{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.baskerville{
  font-family:'JBaskerville', serif;
}
/* Global Font Sizes */
.text-tiny{
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
}

.text-small{
  font-size: 24px;
  line-height: 1.2;
}
hr{
  background: black;
  display: block;
  margin:25px 25px;
}
.text-medium{
  font-size: 45px;
  line-height: 1.2;
}
.text-large{
  font-size: 72px;
  line-height:1.2;
  line-height: 1.2;
}

.oops-message{
  text-align: center;
  width:100%;
  max-width: 1050px;
  margin:20px auto 0;
}
@media(max-width: 768px){
  .text-small{
    font-size: 18px;
  }
  .pastpage .baskerville.text-small{
    font-size:24px;
  }
  .text-medium{
    font-size: 24px;
  }
  .text-large{
    font-size: 35px;
  }

}
/* Links and buttons */
a{
  color:inherit;
  border-bottom: 3px dotted black;
  text-decoration: none;
}
.link{
/*  text-decoration: underline;*/
}
a:hover{
  color:inherit;
  text-decoration: none;
    border-bottom: 3px solid black;
}
.nav-bookstore a{
  border:none;
}
a:hover span, a:hover h1, a:hover p{
  border:none;
}
strong{
  font-family: "BrownPro";
}
.grid-item, .list-item, .grid-item:hover, .list-item:hover{
  border:none;
}
button:hover{
  color:white;
  background: black;
}
button{
  width:195px;
  outline:none;
  transition: 0.1s;
  padding:0 10px;
  background:transparent;
  border:1px solid black;
}
input:focus{
  outline:none;
}
button.on:hover{
  color:black;
  background: white;
}
button.on{
  outline:none;
  transition: 0.1s;
  padding:0 25px;
  background:black;
  color:white;
  border:1px solid black;
}
@media(max-width: 768px){
  #light-box img {
    max-height:70vh;
    max-width: 80vw;
  }
}