.list-item{
	border-bottom:1px solid black;
	padding:8px 0;
	display: none;
}
.list-item:last-child{
	border-bottom:none;
}
.list-item-menu *{
	font-weight: bold;
}
.list-item-menu{
	padding:8px 0 0;

}
#exhibitionTrigger.on,#eventTrigger.on{
	border-bottom:1px dotted black;
}
#exhibitionTrigger,#eventTrigger{
	cursor: pointer;
}

.list-item.on{
	display:flex;
}
.list-item-menu.list-item{
	display: flex;
}
.list-item:last-child, .list-item:last-child:hover{
	border:none;
}
.list-item:hover{
	border-bottom:1px solid black;
}
.list-item .col-special{
	text-align: left;
	padding:0 10px;
	width: 20%;
}
.col-special img{
	width:100%;
}
.btn,.btn:hover, .btn:focus, .btn:active, .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle{
	font-size:inherit;
	color: black;
    border-radius: 0;
    border: none;
    line-height: 1;
    padding: 0;
    background: transparent;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus{
	box-shadow:none;
}
.dropdown-toggle{
	text-align: left;
}
.dropdown-item:hover{
	color:white;
	background: black;
}
.dropdown-item{
	padding: 0.2rem 1rem;
}
.dropdown-menu{
	padding:0;
}
button[aria-expanded="true"]{
	background: black !important;
	color:white !important;
	width:10rem;
	padding:4px !important;
	margin-bottom:-2px !important;
}
.dropdown-item{
	border-bottom:1px solid black;
}
.dropdown-item:last-child{
	border:none;
}
.dropdown-menu.show {
    border-radius: 0;
    font-size: inherit;
    border: 1px solid black;
}
.view-space{
	margin:0 25px;
}
.list-item .selectpicker{
	margin-left:10px;
	border:1px solid black;
}

@media(max-width: 768px){
	.view-space{
	margin:0;
	padding:0px;
	}
	.list-item-menu .col-special{
		display: none;
	}
	.list-item-menu .col-special:nth-child(2){
		display: inline-block;
		width: 50%;
		font-size:24px;
		border-bottom:1px solid black;
		margin:0;
		padding:5px 0;
	}
	.list-item-menu .col-special:nth-child(3){
		display: inline-block;
		width: 50%;
		font-size:24px;
		background: black;
		color:white;
		margin:0;
		padding:5px 10px;
	}
	.list-item-menu{
		margin:0;
		position: fixed;
		width:100%;
		left:0;
		top:80px;
		background: white;
		z-index: 9;
		border-bottom:none;
		padding:0;
		padding:0 10px;
	}
	.list-item-menu .col-special:nth-child(3) button{
		color:white;
	}
	
	.list-item.mew .col-special:first-child{
		text-align: left;
		padding:0 10px;
		width: 50%;
		margin-bottom:50px;
	}
	.list-item.mew .col-special:nth-child(2){
		display: none;
	}
	.list-item.mew .col-special:nth-child(3){
		display: none;
	}
	.list-item.mew .col-special:last-child{
		text-align: left;
		padding:0 10px;
		width: 50%;
	}
	.sub-menu{
		display: none;
	}
	.pastpage.list-header .main-area{
		margin-top:120px;
	}
   .pastpage.list-header .list-item-menu .col-special:nth-child(3) .dropdown-item{
   	width: 195px;


   }
   .list-item-menu .col-special:nth-child(3) .dropdown-menu.show{
     left:-11px !important;
     max-height: 500px;
     overflow: scroll;
   }
	.pastpage.list-header nav .logo{
		width: auto;
		height: auto;
		left: 8px;
		 padding: 11px 10px;
		 transform: none;
	}
	.pastpage.list-header .header.text-large, .pastpage.small-header .header.text-large{
		width: 100%;
	}
	.pastpage.list-header .whitearea, .pastpage.small-header .whitearea{
		background: transparent;
	}
	.list-item.mew{
/*		height: 150px;*/
		position: relative;
		overflow: hidden;
	}
	.col-special .mobile-title{
		text-align: left;
		padding:0;
		margin-top:10px;
		position: absolute;
		bottom:0;
		width: 50%;
		font-family: BrownPro;
		font-weight: bold;
	}
	.list-item-menu{
		display: none;
	}
	.list-item .people{
		display: none;
	}
	.grid-view{
		padding:0 5px;
	}
}