.header-info{
	text-align: center;
	border-top:1px solid black;
	border-bottom:1px solid black;
	display: block;
	width: calc(100% - 20px);
	margin:15px 10px;
	padding: 10px 0;
	position: relative;
}
.selected-press-div, #collaborators{
	display: none;
}
h1.mailing{
	border-bottom:none;
	text-align: center;
	padding:20px 0;
	margin:20px 0;
}
.alice-wrapper{
	position: relative;
}
.alice-button{
	position:absolute;
	top:0;
	left:0;
	width:33%;
	opacity: 0;
	height:100%;
	cursor:url('./left.png') 50 50, auto;
}
.alice-button.alice-button-prev{
	cursor:url('./left.png') 50 50, auto;
}
.alice-button.alice-button-next{
	right:0;
	left:auto;
	cursor:url('./right.png') 50 50, auto;
}
.visit-us{
	line-height: 1.2;
}
.info-page .top .alice-carousel__dots{
	bottom:-35px;
}
.info-page .top .alice-carousel__dots-item:hover, .info-page .top .alice-carousel__dots-item.__active{
	background: black;
}
.info-page .top .alice-carousel__dots-item{
	border:1px solid black;
}
.info-page .alice-carousel{
	 margin-bottom:25px;
}
.info-page .top img{
	margin:0;
}
.info-page .top .caption{
	display: none;
}
.info-page .text-large.description-text{
	line-height: 1;
	margin-top:20px;
}
.info-page .col-sm-12{
	padding:0 10px;
	width: calc(100% - 20px);
	margin-left:10px;
}
.collaborator-header{
	border-top:none;
	padding-top:0;
}
.info-page .col-12 li a, .info-page .col-12 li em{
	width: 33%;
	text-decoration: none;
	border:none;
	float: right;
	display: inline-block;
	text-align: center;
}
.info-page .col-12 li{
	padding:10px 0;
}
.info-page .col-12 li, .press-header div{

	overflow: hidden;
	border-bottom:1px solid black;
}
.press-header div span{
	width: 33%;
	display: inline-block;
	float:right;
	text-align:center;
	padding:10px 0;
}
.info-page .col-12 li em{
	font-style: normal;
	width: 33%;

}
.col-12 iframe{
  width: 100%;
}
.contact-text{
		text-align: center;
}
.selected-press-h1{
	border-bottom:none;
}
.contact-text{
	margin-bottom:10px;
}
.contact-text p{

	margin-bottom: 0;

}
 .col-12 iframe{
	 	width: 100%;
	 }
@media(max-width: 768px) {
	 .col-12 iframe{
	 	width: calc(100% - 20px);
	 }
	 .info-page nav{

	 }
	 .info-page iframe{
	 	display: none;
	 }
	 .info-page .contact-text{
	 	text-align:left;
	 }
	 .info-page .contact-text h3{
	 	margin-bottom:0;
	 }
	 .info-page .visit-us h3{
	 	margin-bottom:0;
	 }
	 .info-page .visit-us p{
	 	margin-top:0;
	 	font-family:"Baskerville";
	 }
	  .info-page .main-area{
	  	margin-top:90px;
	  }
	 .info-page .header.text-large{
	 	border:none;
	 	position: fixed;
	 	font-weight: bold;
	 	top:0;
	 	z-index: 8;
	 	background: white;
	 	padding-top:25px;
	 	left:0;
	 	margin:0;
	 	height:80px;
	 	width:100%;
	 	font-size:24px;
	 	background: white;
	 }
}