.logo{
	padding: 10px 15px;
    top: 24px;
    left: 24px;
	position: absolute;
	z-index:9;
	width:180px;
	font-weight:bold;
	height: 87px;
	background: black;
	color:white;
	line-height:1.5;
	cursor: pointer;
	transition:0.1s;
	background-size:cover;
}
.logo:hover{
	filter:invert(1);
}
.pastpage .logo{
	top:31px;
}
.Homepage nav .logo{
	display:none;
}

@media(max-width: 768px){
	nav{
		border-bottom:1px solid;
		height:80px;
		width: calc(100% - 16px);
		left: 8px;
	}
	.pastpage .logo{
		top:10px;
	}
	.logo, .pastpage nav .logo {
	    width: auto;
	    height: auto;
	    padding: 11px 10px;
	    top: 10px;
	    position: fixed;
	    left: 10px;
	}
	.pastpage nav .logo {
		left:8px;
	}

}