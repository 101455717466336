.event-page.announcement-page header{
	min-height:500px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.event-page.announcement-page .banner{
	margin-bottom:10px;
}
.event-page.announcement-page .col-12 img{
	width: 100%;
 height:auto;
}
.event-page .page-skinny{
	display: block;
	width: 50%;
	min-width: 800px;
	margin:0 auto;
}

@media(max-width: 768px){
	.exhibition .announcement-page.event-page .header{
		display:none;

	}
	.exhibition .announcement-page.event-page .banner.row::before{
		content:"Blog";
		display: block;
		z-index: 8;
	 	width:calc(100% - 20px);
	 	line-height: 2.5;
	 	padding:10px 0;
	 	top:0;
	 	background:inherit;

	 	text-align: center;
		position: fixed;
	}
	.event-page .page-skinny{
		min-width: 0;
		width: 100%;
	}
	.event-page.announcement-page header{
		min-height: 300px;
	}
}
/*.exhibition .header {

padding: 23px 0;
    margin: 18px 25px 0;

}
.three-column{
	vertical-align: top;
}
.three-column:first-child{
	font-weight:bold;
}
.three-column img{
	max-width:100%;
}
.three-column:nth-child(2){
	border-left:1px solid black;
	border-right:1px solid black;

}
.happening nav .logo{
	display: none;
}

.happening .nav-bookstore{
	transform:rotate(-25deg) translateY(21px) translateX(-39px);
}
.banner{
	margin-bottom:25px;
	background-position: center;
	background-size: cover;
	min-height:350px;
	background-repeat: no-repeat;
}
.banner h1{
	text-align: center;
	display: block;
	width:100%;
	height:100%;
	max-width: 1000px;
	margin:0 auto;
	margin-top:150px;
	transform:translateY(-25%);
}

@media(max-width: 768px){
	.happening .logo{
		height:inherit;
		width:inherit;
		top:10px;
		left:15px;
	}
	.exhibition .header{
		
		padding:0;
		margin:0 15px;
		margin-top:50px;
	}
	.banner h1{
		margin:0;
		margin-top:15px;
		transform: translateY(0%);
	}
	.banner{
		min-height: 200px;
		padding:10px;

	}
	.happening .nav-bookstore {
		top:0;
	    right: 5px;
	    max-width: 67px;
	    overflow: hidden;
	    text-decoration: none;
	    font-weight: bold;
	    width: auto;
	    display: inline-block;
	    float: right;
	    transform: rotate(-12deg) translateY(1px) translateX(-18px);
	}
}
*/