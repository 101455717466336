.grid-image{
	height: 150px;
	background-size:cover;
	background-position:center;
}
.wave-bear{
	width:50%;
	display: block;
	margin: 0 auto;
}
.grid-item{
	display: none;
}
.grid-item.on{
	display:block;
}
.upcoming.grid-item h1.title{
	font-weight:normal;
}
.grid-item h1{
	font-weight:bold;
	margin:0;
	margin-bottom:0;
}
.info-page .header{
	    margin: 13px 25px 0;
}
.grid-item h1.title{
	padding-top:25px;
}
.upcoming.grid-item h1.title{
	padding-top:0;
}
.upcoming.grid-item .grid-image{
	margin:10px 0;
}
.upcoming.grid-item{
	padding:0px;
}
.upcoming.grid-view.row{
	margin:0;
	margin-bottom:15px;
}

.grid-item.upcoming h1,  .grid-item.upcoming h2{
	text-align: center;
	font-weight:normal;
/*    padding-bottom: 25px;*/
/*    padding-top: 0;*/
}
.upcoming.grid-item > div{
	border:1px solid black;
	margin:15px 15px 0px 0px;
	padding:15px;
}
.upcoming.grid-item:nth-child(even) > div{
	margin-right:0;

}
.grid-item.col-special{
  margin:15px 15px 0px 0px;
  padding:0;
  width: calc(20% - 15px);
}
.grid-item.col-special:nth-child(5){
  margin-right:0;
  padding:0;
}
.grid-view.row{
	margin:0;
}

.upcoming .grid-image{
	height:300px;
	border:1px solid black;
}
@media(max-width: 768px){
	.grid-image{
		height:80px;
		margin: 0 auto;
		display: block;

	}
	.upcoming.grid-item > div{
		margin:10px;
	}
	.upcoming .grid-image{
		width: 100%;
		height: 200px;
	}
	.grid-item.current .grid-text{
		display: block;
		width:calc(100%);
		margin:0;
		float:none;

	}
}