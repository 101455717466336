.current .grid-image{
	height: 50vh;
	background-size:cover;
	background-position:center;
}
.grid-item{
	display: none;
}
.grid-item.on{
	display:block;
}
.grid-item h1{
	font-weight:bold;
	margin:0;
	margin-bottom:0;
}
.grid-item h1.title{
	padding-top:25px;
}
.grid-item.current.on{
	display: block;
	height: 50vh;
	border:1px solid black;
	margin:15px 0;
	overflow: hidden;
}

.grid-item.current .grid-text, .grid-item.current .grid-image{
	display: inline-block;
	width:calc(50%);
	margin:0;

}
.grid-item.current .grid-text{
	text-align: center;
	padding:15px;
	height:calc(50vh - 30px);
	display: flex;
	flex-direction:column;
	justify-content: space-between;
}
.grid-item.current:nth-child(even) .grid-image, .grid-item.current:nth-child(even) .grid-text{
	float:left;
}
.grid-item.current:nth-child(odd) .grid-image, .grid-item.current:nth-child(odd) .grid-text{
	float:right;
}
.mobile-title{
	display: none;
}
.desktop-title{
	display: block;
}
@media(max-width: 768px){
	.grid-image{
		display: block;
		width:calc(90%);
		margin:0 auto;
		float:none;
	}
	.grid-item.current.on{
		height: auto;
		margin:10px5px;
	}

	.mobile-title{
		text-align: center;
		font-weight: normal;
		padding:0 10px;
		display: block;
	}
	.desktop-title{
		display: none;
	}
	.grid-item.current .grid-text, .grid-item.current .grid-image{
		width: auto;
		height: 150px;
	}
	.grid-item h1.title{
		font-size:34px;
	}
    .grid-item.current .grid-image,.grid-item.current:nth-child(odd) .grid-image, .grid-item.current:nth-child(odd) .grid-text, .grid-item.current:nth-child(even) .grid-image, .grid-item.current:nth-child(even) .grid-text{
    	width:calc(90%);
    	float:none;
    	display: block;
    	margin:10px auto;

	}
	.grid-item.current .grid-text{
		display: block;
		width:calc(100%);
		margin:0;
		height: auto;
		float:none;

	}
}