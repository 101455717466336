.carousel-slide{
	background-color: white;
	height: 100vh;
	width:100vw;
/*	position: absolute; */
	top:0;
/*	transition: 1.2s;
	left:100vw;*/
	background-size:cover;
	text-align: center;
	padding:25px;
}
.sixtypercent{
	position:absolute;
	height: 100%;
	left:20%;
	width: 60%;
}
.mobile-controls{
	display: none;
}
.full-bleed{
	overflow: visible;
	top:0;
	left:50%;
	transform:translateX(-50%);
	pointer-events: none;
	z-index: 2;
	text-align: center;
	position: absolute;
}

.full-bleed img{

	top:0;
	left:0;
	width: auto;
	min-height:100vh;
/*	min-height:100vh;*/
}
.crop-height .carousel-slide-image{
	max-height: none;
	min-height: none;
	margin-top:-50%;
	transform:translateY(50%);
}
.crop-height {
	overflow: hidden;
	max-height:40vh;
	height:40vh;
}

.crop-width .carousel-slide-image{
	max-height: 40vh;
	overflow-y:visible;
	width: auto;
	max-width: none;
	margin-left:-25%;

}
.crop-width {
	overflow-x: hidden;
	max-width:40vh;
	width:40vh;
}

.carousel-slide a{
 border:none;
 transition:0.2s;
}
.carousel-slide a{
	display: inline-block;
}
.carousel-slide{
	cursor: none;
}
.carousel-slide *{
	cursor:none;
}
.Homepage .main-carousel{
	cursor: none;
}
.Homepage .main-carousel *{
	cursor: none;
}
.carousel-slide .logo{
	cursor:pointer;
}
.carousel-slide a:hover{
 border:none;
 opacity: 0.9;
/* cursor:auto;*/
/* transform: rotateY(-25deg)*/
}
.Homepage .prev-invisible, .Homepage .next-invisible{
  cursor:inherit;
}
.click-cursors{
	opacity: 0;
	position: fixed;
	pointer-events: none;
	z-index: 999;
}
.carousel-slide > div{
display: flex;
	flex-direction: column;
	justify-content:space-between;
		height: calc(100vh - 50px);
}
/*.carousel-slide.selected{
	left:0;
}
.carousel-slide.passed{
	left:0%;
}*/
.carousel-slide h1{
	margin-top:5px;

}
.carousel-slide .description{

}

.carousel-slide-image{
		display: block;
		margin:0 auto;

margin-bottom:15px;
		width:auto;

		max-height:25vw;
	min-height:25vw;
	position: relative;
}

.carousel-slide h1{
	position: relative;
	text-align: center;



}


.carousel-slide .description{
	text-align: center;
	position: relative;



	/*position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%);*/
}
.arrow.off{
	display: none;
}
.arrow{
	z-index: 7;
	position: absolute;
	left:25px;
	top:calc(50% - 200px);
}
.arrow svg{
  width:300px;
}
.arrow-next{
	left:auto;
	right:25px;
}
.arrow-next svg{
	transform:scaleX(-1);
}
.arrow svg path{
	fill:none;
	stroke: black;
	stroke-width:2px;
	stroke-linecap: round;
}
.custom-slider{
		max-width:100vw;
		overflow-x:hidden;
		position: relative;
		min-height: 100vh;
	}

@media(max-width: 768px){
	.arrow svg{
		width: 50px;
	}
	.arrow svg path{
		stroke-width:5px;
	}
	.arrow{
		left:10px;
		top:calc(50% - 50px);
	}
	.arrow-next{
		left:auto;
		right:10px;
	}
	.carousel-slide{
		height: calc(95vh);
		padding:100px 10px 35px;
	}
	.carousel-slide > div{
		position: relative;

	}
	.prev-invisible.invisible-block,.next-invisible.invisible-block{
		display: block;
		opacity: 1;
		height: 50px;
		display: none;
		top:auto;
		z-index: 9;
		left:-10px;
		bottom:-40px;
		position: absolute;
		width: 0; 
		background: transparent;
		height: 0; 
		border-top: 60px solid transparent;
		border-bottom: 60px solid transparent;
		border-left: 60px solid green;
	}

	.next-invisible.invisible-block{
		right:-10px;
		left:auto;
	}
	.prev-invisible.invisible-block{
		transform:scaleX(-1);
		background: transparent;
		outline:black;
	}
	.mobile-controls{
		display: block;
		width:100%;
		border-top:1px solid black;
		padding:10px 0;
		margin-bottom:-10px;
	}
	.mobile-controls span{
		padding-top:8px;
		display: inline-block;
	}
	.mobile-controls svg:first-child{
		float:left;
	}
	.mobile-controls svg:last-child{
		float:right;
		transform:scaleX(-1);
	}
	.carousel-slide > div{
		height: calc(95vh - 135px);
	}
	.carousel-slide-image{
		max-height:30vh;
		width:auto;
	}
	
}