.event-page{
	margin:0 25px;
}

.event-page .top .caption{
	text-align: center;
	margin:0;
	border:none;
	padding:5px 0;
}
a.book-button, a.book-button:hover{
	border:none;
}
.event-page .toolbar .col-6:first-child{
	text-align: left;
}
.event-page .toolbar .col-6:last-child{
	text-align: right;
}
.second-column .top img{
	margin:0;
}
.event-page .toolbar .col-6 p{
	margin:0;
}
.image-caption{
	text-align: center;
}
.toolbar .col-6{
	padding:0;
}
.event-page .toolbar{
	margin:10px 0;
	width:100%;
}
.event-page {
	border-bottom:1px solid;
	padding-bottom:15px;
	margin-bottom:15px;
}
.event-page .header-with-image{
	top:200px;
}
@media(max-width: 768px){
	.event-page .toolbar{
	    font-size: 24px;
	    line-height: 1.2;
	}
	.exhibition .event-page .header{
		margin:0;
	}
}
/*.exhibition .header {

  padding: 23px 0;
    margin: 18px 25px 0;

}

.three-column{
	vertical-align: top;
}
.three-column:first-child{
	font-weight:bold;
}
.three-column img{
	max-width:100%;
}
.three-column:nth-child(2){
	border-left:1px solid black;
	border-right:1px solid black;

}
.happening nav .logo{
	display: none;
}

.happening .nav-bookstore{
	transform:rotate(-25deg) translateY(21px) translateX(-39px);
}
.banner{
	margin-bottom:25px;
	background-position: center;
	background-size: cover;
	min-height:350px;
	background-repeat: no-repeat;
}
.banner h1{
	text-align: center;
	display: block;
	width:100%;
	height:100%;
	max-width: 1000px;
	margin:0 auto;
	margin-top:150px;
	transform:translateY(-25%);
}
@media(max-width: 768px){
	.happening .logo{
		height:inherit;
		width:inherit;
		top:10px;
		left:15px;
	}
	.exhibition .header{
		
		padding:0;
		margin:0 15px;
		margin-top:50px;
	}
	.banner h1{
		margin:0;
		margin-top:15px;
		transform: translateY(0%);
	}
	.banner{
		min-height: 200px;
		padding:10px;

	}
	.happening .nav-bookstore {
		top:0;
	    right: 5px;
	    max-width: 67px;
	    overflow: hidden;
	    text-decoration: none;
	    font-weight: bold;
	    width: auto;
	    display: inline-block;
	    float: right;
	    transform: rotate(-12deg) translateY(1px) translateX(-18px);
	}
}*/