.grid-image{
	height: 150px;
	background-size:cover;
	background-position:center;
}
.grid-item .grid-text h1{
	font-weight: normal;
}
.grid-item{
	display: none;
}
.grid-item.on{
	display:block;
}
.grid-item.col-special{
	width:20%;
	padding:5px 5px 10px;
}
.grid-item.past h1{
/*	font-weight:normal;*/
	margin:0;
	margin-bottom:0;
}
.grid-item.past h1.title{
	padding-top:5px;
	font-weight:bold;
}
@media(max-width: 768px){
	.grid-image{
		height:80px;
	}
}