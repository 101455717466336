nav{
	position: fixed;
	z-index: 9;
	width:100%;
	top:0;
	left:0;
}

.nav-bookstore{
	right:5px;
	text-decoration: none;
	font-weight:bold;
	width: auto;
	display: inline-block;
	float:right;
	transform:rotate(-45deg) translateY(25px) translateX(-45px);
	
}

.nav-bookstore a{
	text-decoration: none;
}
.nav-bookstore:hover{
	filter:invert(100%);
	-webkit-filter:invert(100%);
	-moz-filter:invert(100%);
	-o-filter:invert(100%);
}
@media(max-width: 768px){
	.nav-bookstore {
		top:0;
		display: none;
	    right: 5px;
	    max-width: 67px;
	    overflow: hidden;
	    text-decoration: none;
	    font-weight: bold;
	    width: auto;
/*	    display: inline-block;*/
	    float: right;
	    transform: rotate(-12deg) translateY(0px) translateX(-10px);
	}
	.logo{
		position: fixed;
	}
}
@media(min-width: 768px){
.happening.small-header .logo{
	position: fixed;
}
.happening.small-header .banner{
	position: fixed;
	left:15px;
    top: 0;
    width: 100%;
    z-index: 2;
}
.happening.small-header .header.text-large.baskerville{
  font-size:24px;
  line-height: 1.3;
  position: fixed;
  top:0;
  background: white;
  z-index: 1;
  width:calc(100% - 50px);
}
 .happening.small-header .main-area{
	margin-top:125px;
}
.happening.small-header .header {
    padding: 10px 0;
    margin: 18px 25px 0;
    font-size:24px;
}

 .happening.small-header .whitearea{
	background: white;
	position: fixed;
	width:100%;
	z-index: 1;
	overflow: hidden;
	height:80px;
	top:0;
	left:0;
}
 .happening.small-header .sub-menu{
	background: white;
	z-index: 1;
	top:60px;
	width:calc(100% - 50px);
	position: fixed;
}
 .happening.small-header nav .logo, .happening.small-header .exhibition > .logo {
  border: none;
  font-size: 24px;
  height: auto;
  width:auto;
  line-height: 1;
  margin-top:5px;
  padding:0;
/*  background:transparent;*/
  outline:none;
}
.happening.small-header .nav-bookstore{
	transform:none;
	top: 25px;
    position: fixed;
    right: 25px;
}
}