#main-menu ul{
	list-style: none;
	padding:0;
	margin-top:100px;
}
#main-menu ul li:first-child a{
   padding-top:50px;
  }
#main-menu a{
  text-decoration: none;
}
.info-menu{
      position: absolute;
    bottom: 25px;
    width: calc(100% - 50px);
}

#main-menu{
	background: green;
    display: inline-block;
    position: fixed;
    z-index: 10;
    height: 100vh;
    transition:0.2s;
    padding: 25px;
    top:0;
    width: 235px;
    transform:translateX(-100%);

}
.menu-image img{
  max-width: 70%;
  max-height:15vh;
  margin-top:25px;
}
#main-menu.open{
	top:0;
	transform:translateX(0);
}
#main-menu.open .blanket{
  width:100vw;
  height:100vh;
  background: transparent;
  position: fixed;
  top:0;
  left:235px;
}
#main-menu.open .blanket span{
  display: none;
}

@media(max-width: 768px) {
  #main-menu.open .blanket{
    left:0;
    height:0;
    overflow: visible;
  }
  #main-menu.open .blanket span{ 
    height: 1px;
    background: white;
    position: fixed;
    display: block;
    width: 60px;
    right:10px;
    top:20px;
    z-index: 999;
  }
    #main-menu.open .blanket span:first-child{
      transform: translateY(20px) rotate(45deg);
      transform-origin: center; 
  
    }
     #main-menu.open .blanket span:last-child{
      transform: translateY(20px) rotate(-45deg);
      transform-origin: center;  
    }
  #main-menu{
      width: calc(100%);
      display: block;
      padding:10px;
      height: auto;
      min-height: 100vh;
  }
  .info-menu{
    margin-top:45px;
    width:100%;
      position: absolute;
      bottom:0;
      font-size:14px;
}


  #main-menu ul.text-small.baskerville{
    font-size:35px;
    text-align: center;
    display: block;
    position: relative;
    margin-top: 75px;
  }
  #main-menu ul a:first-child{
   padding-top:10px;
  }
  #main-menu ul li:first-child a{
   padding-top:10px;
  }
  #main-menu ul a{
    display: block;
    padding:10px;
    border-bottom: 1px solid inherit;
  }
  #main-menu ul a{
    border-top:1px solid inherit;
  }
  .menu-image{
    display: none;
  }
  
}