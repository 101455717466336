.header-info{
	text-align: center;
	border-top:1px solid black;
	border-bottom:1px solid black;
	display: block;
	width: calc(100% - 20px);
	margin:15px 10px;
	padding: 10px 0;
	position: relative;
}

.press-link{
	border: none;
	margin-bottom:2rem;
	display: block;
}
.press-link:hover{
	border:none;
}
.press-link p{
	margin:0;
}
.press-link:hover .underline{
	border-bottom: 1px solid;
}
.underline {
	display: inline-block;
    border-bottom:1px dotted;
}

 