footer{
	display: block;
	margin:0 25px;
	margin-right:25px;
	margin-left:25px;
	margin-top:15px;
	border-top:1px solid black;
	padding:15px 0;
}
footer .col-sm-3{
	text-align: center;
	display: flex;
	flex-direction: column;	
	justify-content: center;
	margin-bottom:1rem;
}
footer .col-sm-3 p{
	font-weight: normal;
	margin:0;
}
footer .col-sm-3 h1{
	font-size: 18px;
    margin: 0;
	font-weight: bold;
}
@media(max-width: 768px){
   footer .col-sm-3{
   	text-align: left;
   	word-break: break-word;
   	font-family: "BrownPro";
   }
   footer .col-sm-3 p{
   	font-size:14px;
   	font-family: "BrownPro";
   }
}