.flickity-prev-next-button{
	background: none !important;
}
.flickity-prev-next-button:focus{
	outline:none;
}
.flickity-prev-next-button{
	width:400px;
	height:400px;
}
.flickity-button-icon path{
 stroke:black;
 fill:none;
 stroke-width:3px;
 stroke-linecap:round;
}
.flickity-button:disabled{
	display: none;
}


.invisible-block{
	opacity: 0;
background: blue;
	position: absolute;
	left:0;
	width:50vw;
	z-index: 0;

	top:0;
	height:100vh;
}
.next-invisible{
	background:red;
	left:50vw;
/*	cursor:pointer;*/
	cursor:url('./right.png') 50 50, auto;


}
.prev-invisible{
		cursor:url('./left.png') 50 50, auto;
}
.fat-arrow.left-arrow{
 width: 0; 
  height: 0; 
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent; 
  
  border-right:100px solid inherit;
}
/*.carousel-slide-image,.carousel-slide h1,.carousel-slide .description *:not(a){
	pointer-events: none;
}*/
.fat-arrow.right-arrow{
 width: 0; 
  height: 0; 
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  
  border-left: 100px solid inherit;

}
.fat-arrow{
	pointer-events: none;
	width: 0;
	height:0;
	background: none;
	top:0;
	z-index:9;
	position: fixed;
	display: none;
}
.fat-arrow.on{
	display: none;
}

/*.flickity-prev-next-button.previous::before{
	content:'Previous';
	margin-top:-25px;
	height: 65px;
	display: block;
}
.flickity-prev-next-button.next::before{
	content:'Next';
	margin-top:-25px;
	display: block;
	height: 65px;
}*/